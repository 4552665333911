import React, { useRef } from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Image from "../components/image"
import SEO from "../components/seo"

import lpHeroDesktop2x from "../images/lp1/lp1-hero@2x.jpg"
import lpHeroMobile2x from "../images/lp1/lp1-hero-mobile@2x.jpg"
import ChocolateChip from "../images/chocolate-chip.png"
import ChocolateChipSpoon from "../images/lp1/spoon-chocolatechip.png"
import PeanutButter from "../images/peanut-butter.png"
import FudgeBrownie from "../images/fudge-brownie.png"
import FudgeBrownieSpoon from "../images/lp1/spoon-fudgebrownie.png"
import AccoladesDesktop from "../images/lp1/accolades-desktop.jpg"
import AccoladesMobile from "../images/lp1/accolades-mobile.jpg"
import ReadyDesktop from "../images/lp1/ready-desktop.png"
import ReadyMobile from "../images/lp1/ready-mobile.png"
import Stars from "../images/stars.png"
import TryImg from "../images/try-img-1.png"
import TryCtaDesktop from "../images/try-cta.png"
import TryCtaMobile from "../images/try-cta-mobile.png"
import logo from "../images/logo.png"

import lp2HeroDesktop from "../images/lp2/lp2-hero-desktop.jpg"
import lp2HeroMobile from "../images/lp2/lp2-hero-mobile.jpg"
import ChocCardDesktop from "../images/lp2/lp2-choc-card.png"
import FudgeCardDesktop from "../images/lp2/lp2-fudge-card.png"
import PBCardDesktop from "../images/lp2/lp2-pb-card.png"
import GASDesktop from "../images/lp2/lp2-gas-desktop.jpg"
import GASMobile from "../images/lp2/lp2-gas-mobile.jpg"
import PSDesktop from "../images/lp2/lp2-ps-desktop.jpg"
import PSMobile from "../images/lp2/lp2-ps-mobile.jpg"
import TryFavsDesktop from "../images/lp2/lp2-try-favorites-desktop.png"
import TryFavsMobile from "../images/lp2/lp2-try-favorites-mobile.png"
import EnjoyFreshDesktop from "../images/lp2/lp2-enjoy-fresh-desktop.jpg"
import EnjoyFreshMobile from "../images/lp2/lp2-enjoy-fresh-mobile.jpg"

import * as Icon from "../components/Icons"

const IndexPage = () => {
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  return (
    <Layout>
      <SEO title="Get 15% Off" />
      <div className="w-full relative">
        <div
          className="absolute"
          style={{ width: 150, height: 150, top: "10px", left: "10px" }}
        >
          <a href="https://pssnacks.com">
            <img src={logo} alt="" />
          </a>
        </div>
        <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/cookie-dough-sample-pack">
          <div className="w-full hidden md:block">
            <img src={lp2HeroDesktop} alt="" className="w-full mx-auto m-0" />
          </div>
          <div className="w-full md:hidden mx-auto">
            <img src={lp2HeroMobile} alt="" className="w-full mx-auto m-0" />
          </div>
        </a>
      </div>

      <div className="w-full">
        <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/cookie-dough-sample-pack">
          <div className="w-full hidden md:block">
            <img src={PSDesktop} alt="" className="w-full m x-auto m-0" />
          </div>
          <div className="w-full md:hidden mx-auto">
            <img src={PSMobile} alt="" className="w-full mx-auto m-0" />
          </div>
        </a>
      </div>

      <div className="bg-brand-humming-bird-500 py-16">
        {/* Desktop */}
        <div className="max-w-5xl mx-auto hidden md:block">
          <div className="grid grid-cols-3 gap-8 my-12">
            <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/fudge-brownie-cookie-dough">
              <img src={FudgeCardDesktop} alt="" />
            </a>
            <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/chocolate-chip-cookie-dough">
              <img src={ChocCardDesktop} alt="" />
            </a>
            <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/peanut-butter-cookie-dough">
              <img src={PBCardDesktop} alt="" />
            </a>
          </div>
        </div>

        {/* Mobile */}
        <div className="grid md:hidden grid-cols-1 gap-12 my-12 px-8">
          <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/fudge-brownie-cookie-dough">
            <img src={FudgeCardDesktop} alt="" />
          </a>
          <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/chocolate-chip-cookie-dough">
            <img src={ChocCardDesktop} alt="" />
          </a>
          <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/peanut-butter-cookie-dough">
            <img src={PBCardDesktop} alt="" />
          </a>
        </div>
      </div>

      {/* <div className="w-full bg-brand-wax-flower-500 py-12">
        <div className="max-w-5xl mx-auto">
          <a href="https://ps-snacks.com/collections/products/products/cookie-dough-sample-pack">
            <img
              src={TryFavsDesktop}
              alt=""
              className="w-full mx-auto hidden md:block"
            />
            <img
              src={TryFavsMobile}
              alt=""
              className="w-full mx-auto md:hidden px-12"
            />
          </a>
        </div>
      </div> */}

      <div className="w-full pb-12 bg-brand-wax-flower-500 px-8 py-12">
        <h2 className="text-3xl md:text-4xl font-campton tracking-wider text-brand-astronaut-500 uppercase text-center">
          …OR TRY ALL YOUR FAVORITES
        </h2>
        <p className="text-xl md:text-2xl font-campton tracking-wider text-brand-astronaut-500 text-center my-16">
          Get free shipping with 2 sampler packs!
        </p>
        <div className="max-w-5xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-24">
            <div className="hidden sm:block">
              <img src={TryImg} alt="" />
            </div>
            <div className="hidden sm:block">
              <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/cookie-dough-sample-pack">
                <img src={TryCtaDesktop} alt="" />
              </a>
            </div>
            <div className="sm:hidden">
              <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/cookie-dough-sample-pack">
                <img src={TryCtaMobile} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mx-auto">
        <img
          src={GASDesktop}
          alt=""
          className="w-full mx-auto hidden md:block my-0"
        />
        <img src={GASMobile} alt="" className="w-full mx-auto md:hidden my-0" />
      </div>

      <div className="w-full-bg-white py-12">
        <a href="https://ps-snacks.com/pages/faqs">
          <img
            src={EnjoyFreshDesktop}
            alt=""
            className="mx-auto w-full hidden md:block"
          />
          <img
            src={EnjoyFreshMobile}
            alt=""
            className="mx-auto w-full md:hidden"
          />
        </a>
        <div className="flex flex-col items-center text-center">
          <p className="font-campton text-brand-astronaut-500 tracking-wider">
            Get free shipping with 2 sampler packs!
          </p>
          <a href="https://ps-snacks.com/discount/WELCOME15?redirect=/products/cookie-dough-sample-pack">
            <div className="px-8 py-2 font-codesaver bg-brand-crimson-500 text-white rounded-md uppercase hover:bg-brand-astronaut-700 transition-all duration-150">
              Shop Packs
            </div>
          </a>
        </div>
      </div>
    </Layout>
    // <div class="relative text-center bg-white">
    //   <h1>Hello, world!</h1>
    //   <p>Check out my awesome waves!</p>
    //   <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     viewBox="0 0 1440 320"
    //     class="block text-brand-wax-flower-500"
    //   >
    //     <path
    //       fill="currentColor"
    //       fill-opacity="1"
    //       d="M0,128L48,112C96,96,192,64,288,96C384,128,480,224,576,224C672,224,768,128,864,96C960,64,1056,96,1152,138.7C1248,181,1344,235,1392,261.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
    //     ></path>
    //   </svg>
    // </div>
  )
}

export default IndexPage
